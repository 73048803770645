import axios from '@/config/httpConfig'
//获取币种信息
export function getCurrencyInfo(data) {
    return axios.post('/currency/getCurrencyInfo', data)
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}
// 获取视图详细信息
export function GetViewInfo(data) {
    return axios.post('/view/getViewInfo', data)
}
// 保存视图
export function SaveView(data) {
    return axios.post('/view/saveView', data)
}
// 获取币种信息
export function GetCurrencyInfo(data) {
    return axios.post('/currency/getCurrencyInfo', data)
}

// 获取用户界面设置
export function getUserSetup(data) {
    return axios.get('/user/getUserSetup', data)
}

// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}

// 更改所有人是否展示伙伴云和队列
export function checkQueueAndPartner(data) {
    return axios.post('/objectdetail/checkQueueAndPartner', data)
}

// 队列
export function queryQueue(data) {
    return axios.post('/objectdetail/queryQueue', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}