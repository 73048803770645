<template>
  <!-- 开发者：王雪茹 -->
  <div>
    <div v-for="(ev, index) in list" :key="index" class="screen_s">
      <!-- :label="index + 1 + '.'"
      label-width="10px"-->
      <span class="num_s">{{ index + 1 }}</span>
      <!-- placeholder="选择字段" -->
      <el-select
        v-model="ev.fieldId"
        :placeholder="$t('label.selectfield')"
        class="select_one"
        filterable
        @change="selectChange($event, index)"
        size="small"
        :disabled="id !== undefined ? true : false"
      >
        <el-option
          v-for="item in optionst_one"
          :key="item.id"
          :label="item.labelName"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="ev.op"
        :placeholder="$t('label.operator')"
        class="select_two"
        filterable
        clearable
        size="small"
        :disabled="id !== undefined ? true : false"
      >
        <el-option
          v-for="item in ev.optionst_two"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <EditableCell
        ref="editableCell"
        :inputType="ev.inputType"
        :options="selecArr"
        :value="ev.val"
        :origin-type="filter"
        :prefix="prefix"
        :fieldId="ev.fieldId"
        :objid="ev.objid"
        :min="0"
        :max="1000000000000000000"
        :disabled="isTarget"
        @remoteSearch="remoteSearch(ev, index)"
        @selectes="ev.fieldId === '' ? '' : selectes(ev)"
        @remoteMethods="remoteMethods"
        style="width: 190px; position: relative"
      >
      </EditableCell>
      <div class="btn_box">
        <span class="btn_s" @click="dels(index)" v-if="list.length !== 1"
          >-</span
        >
        <span class="btn_s" @click="adds" v-if="list.length - 1 === index"
          >+</span
        >
      </div>
    </div>
    <!-- 查找/查找多选 -->
    <!--  <el-dialog title="搜索" -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :checked="searchChecked"
        @changeSelect="changeSelect"
      />
    </el-dialog>
  </div>
</template>
<script>
import SearchTable from "@/components/Form/search-table.vue";
import operator from "@/config/enumCode/operator.js";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import filterValueInputType from "@/config/enumCode/inputType.js";
import * as CommonObjApi from "./api";
import { GetViewGetSelectValue, GetCurrencyInfo } from "./api.js";
import {
  setValueTypeConversion,
  echoValueTypeConversion,
} from "@/config/filter/commaProcessing";
export default {
  components: {
    EditableCell,
    SearchTable,
  },
  props: {
    objId: String,
    viewId: {
      type: String,
      default: "",
    },
    viewInfos: {
      type: Object,
    },
    prefix: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
    },
    optionst_one: {
      type: Array,
    },
    filterName: {
      type: Number,
    },
    id: {
      type: undefined,
      default: undefined,
    },
  },
  created() {
    //判断是新建还是编辑
    if (this.id !== undefined) {
      this.isTarget = true;
    } else {
      this.isTarget = false;
    }
  },
  data() {
    return {
      filter: "filter",
      selecArr: [],
      dataId: 1,
      showSearchTable: false,
      rowIndex: 0,
      searchChecked: false,
      fieldId: "",
      isTarget: false,
    };
  },
  methods: {
    getFilterData() {
      let copyContentsConditions = JSON.parse(JSON.stringify(this.list));
      copyContentsConditions.forEach((item, i) => {
        delete copyContentsConditions[i].type;
        delete copyContentsConditions[i].id;
        delete copyContentsConditions[i].optionst_two;
        if (item.seq) {
          delete copyContentsConditions[i].seq;
        }
      });
      if (this.$refs.editableCell !== undefined) {
        this.$refs.editableCell.forEach((item, index) => {
          if (item.editValue !== "" && item.editValue !== undefined) {
            if (copyContentsConditions[index].inputType == "multi-select") {
              copyContentsConditions[index].val = setValueTypeConversion(
                item.editValue
              );
            } else {
              copyContentsConditions[index].val = item.editValue;
            }
          } else {
            copyContentsConditions[index].val = item.editValue;
          }
        });
      } else {
        copyContentsConditions.forEach((item, index) => {
          item.val = item.val.value;
        });
      }
      this.$emit("getFilterData", copyContentsConditions);
    },
    //筛选器回显处理的数据
    getFilterObjFun(arr, contentcons, optionst_one) {
      if (contentcons && contentcons.length > 0 && optionst_one.length > 0) {
        contentcons.forEach((item) => {
          optionst_one.forEach((itm, ind) => {
            // 
            if (item.fieldId === itm.id) {
              // 币种类型
              if (itm.schemefield_type === "ct") {
                GetCurrencyInfo().then((resItem) => {
                  this.selecArr = resItem.data;
                  this.selecArr.map((valItem) => {
                    (valItem.label = valItem.currencyname),
                      (valItem.value = valItem.currencycode);
                  });
                });
                // 记录类型
              } else if (itm.schemefield_type === "R") {
                this.getRecordTypes();
              } else {
                GetViewGetSelectValue({
                  fieldId: item.fieldId,
                }).then((resItem) => {
                  this.selecArr = resItem.data;
                  this.selecArr.forEach((item, index) => {
                    (item.label = item.codekey), (item.value = item.codevalue);
                  });
                });
              }
              let inputTypes =
                filterValueInputType[
                  itm.schemefieldType === "L" ? "Q" : itm.schemefieldType
                ];
              // 选择框类型为查找多选字段时，才需要回显做处理
              if (inputTypes === "multi-select") {
                item.value = echoValueTypeConversion(item.value);
              }
              arr.push({
                fieldId: item.fieldId,
                op: item.operator,
                seq: item.seq,
                val: {
                  value: item.value,
                },
                inputType: inputTypes,
                optionst_two:
                  operator.TYPE[itm.schemefieldType].getScreeningOpeTypes(),
              });
              this.$emit("assignment", arr, this.filterName);
            }
          });
        });
      }
    },
    async selectes(val) {
      if (val.type === "R") {
        this.getRecordTypes();
      } else if (val.type === "L" && val.fieldId === "ffe202085ACBDD8EusnH") {
        this.getCountrySelectValue();
      } else {
        //(wxr)解决bug编号21297
        //val.fieldId = ffeleadmarketsea02 分配状态时不继续调这个接口GetViewGetSelectValue
        if (
          val.fieldId === "ffe20120329185449704" ||
          val.fieldId === "ffeleadmarketsea02"
        ) {
          return;
        }
        let arr = [];
        let params = {
          fieldId: val.fieldId,
        };
        let res = await GetViewGetSelectValue(params);
        if (res.result) {
          res.data.forEach((item) => {
            let obj = {
              label: item.codekey,
              value: item.codekey,
            };
            arr.push(obj);
            this.selecArr = arr;
          });
        }
      }
    },
    remoteMethods(val, fieldId, objId) {
      let params = {
        fieldId: fieldId,
        objId: "",
        prefix: "",
      };
      CommonObjApi.getLookupInfo(params).then((res) => {
        let data = {
          name: val,
          objId: res.data.prefix,
          fieldId: params.fieldId,
          lkdp: "['']",
        };
        CommonObjApi.queryMoreDataByName(data).then((res) => {
          let options = JSON.parse(res.data);
          options.forEach((option) => {
            option.value = option.id;
            option.label = option.queryname;
          });
          this.selecArr = options;
        });
      });
    },
    // 获取国家或地区选项列表值
    getCountrySelectValue() {
      CommonObjApi.getSelectValue({ fieldId: "country" }).then((res) => {
        if (res.result && res.returnCode === "1") {
          let options = [];
          res.data.forEach((item) => {
            options.push({
              label: item.codevalue,
              value: item.codekey,
            });
          });
          this.selecArr = options;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    remoteSearch(item, num) {
      this.rowIndex = num + 1;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 改变查找/查找多选的值
    changeSelect(row) {
      this.showSearchTable = false;
      if (row.data.id) {
        let value = {
          value: row.data.id,
        };
        let options = {
          label: row.data.id,
          value: row.data.name,
        };
        this.$emit("changeSelect", this.rowIndex, options, this.filterName);
      }
    },
    //新增
    adds() {
      if (this.list.length < 5) {
        this.$emit("addFilterBtn", this.dataId, this.filterName);
      } else {
        return;
      }
    },
    //删除
    dels(id) {
      this.$refs.editableCell.splice(id, 1);
      this.$emit("delsFilterBtn", id, this.filterName);
    },
    //根据筛选字段判断值
    selectChange(val, index) {
      this.fieldId = val;
      this.optionst_one.map((item) => {
        let keys = "";

        if (val === item.id) {
          this.list[index].op = "";
          this.list[index].val = {
            value: "",
          };
          if (item.schemefieldType === "Z") {
            keys = item.expressionType;
            this.list[index].optionst_two =
              operator.TYPE[item.expressionType].getScreeningOpeTypes();
          } else {
            keys = item.schemefieldType;
            this.list[index].optionst_two =
              operator.TYPE[item.schemefieldType].getScreeningOpeTypes();
          }
          this.list[index].op = this.list[index].optionst_two[0].id; // 设置默认操作为第一个选项
          this.list[index].inputType =
            filterValueInputType[keys === "L" ? "Q" : keys];
          this.selecArr = [];
          // 无法替换 接口返回
          if (item.schemefield_type == "ct") {
            GetCurrencyInfo().then((res) => {
              res.data.forEach((item, index) => {
                (item.label = item.currencyname),
                  (item.value = item.currencycode);
              });
              this.selecArr = res.data;
            });
          } else if (item.schemefield_type === "R") {
            this.getRecordTypes();
          } else {
            //(wxr)解决bug编号21297
            //分配状态时label和value需要换位置
            if (val === "ffeleadmarketsea02") {
              GetViewGetSelectValue({
                fieldId: val,
              }).then((res) => {
                res.data.forEach((item, index) => {
                  (item.label = item.codevalue), (item.value = item.codekey);
                });
                this.selecArr = res.data;
              });
            } else {
              GetViewGetSelectValue({
                fieldId: val,
              }).then((res) => {
                res.data.forEach((item, index) => {
                  (item.label = item.codekey), (item.value = item.codevalue);
                });
                this.selecArr = res.data;
              });
            }
          }
        }
      });
    },
    getRecordTypes() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      CommonObjApi.getRecordType(params).then((res) => {
        if (res.result) {
          res.data.recordTypeList.forEach((item) => {
            let obj = {
              label: item.name,
              value: item.name,
            };
            arr.push(obj);
            this.selecArr = arr;
          });
        }
      });
    },
  },
  mounted() {},
  updated() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.screen_s {
  display: flex;
  ::v-deep .editableCell {
    margin-right: 10px;
    input {
      height: 32px;
    }
  }
  .num_s {
    width: 20px;
    height: 30px;
    line-height: 30px;
    margin: 6px 0;
  }
  .el-select {
    margin-right: 10px;
  }
  .btn_s {
    font-size: 24px;
    margin: 0 8px;
    cursor: pointer;
  }
  .select_one {
    width: 119px;
    margin: 6px;
    height: 30px;
  }

  .select_two {
    width: 119px;
    margin: 6px 12px 6px 6px;
    height: 30px;
  }
  ::v-deep .editableCell {
    height: 30px;
    line-height: 30px;
    margin: 0 12px 0 0;
    top: 7px;
    .el-select__tags {
      height: 30px;
      flex-wrap: unset;
      overflow-x: overlay;
      overflow-y: hidden;
    }
    .el-input .el-input__inner {
      height: 30px !important;
    }
    .el-tag {
      margin: 0 5px !important;
    }
  }
  .btn_box {
    width: 100px;
    height: 30px;
    line-height: 30px;
    margin: 6px;
  }
}
</style>
