<template>
  <div>
    <div class="member-box">
      <div>
        <div class="personnel-select">
          <!-- 可选成员 -->
          <span class="view_members">
            {{ $t("vue_label_commonobjects_view_members") }}</span
          >
          <el-input
            :placeholder="$t('label.quickbooks.searchs')"
            @input="searchMember"
            v-model="keyworld"
            class="input-with-select"
          >
            <el-select
              v-model="type"
              slot="prepend"
              @change="tabList"
              :placeholder="$t('label_tabpage_pleaseSelectz')"
            >
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="(item, index) in personOptions"
                :key="index"
              ></el-option>
            </el-select>
          </el-input>
        </div>
        <div class="memberList-left">
          <ul>
            <li
              v-for="member in leftData"
              :key="member.id"
              @click="selectMember(member)"
              :style="{
                backgroundColor: member.isSecelt == true ? '#ddd' : '',
              }"
            >
              {{ member.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="Operation-button">
        <el-button @click="addLeft">
          <i class="el-icon-arrow-left"></i>
        </el-button>
        <el-button @click="addRight">
          <i class="el-icon-arrow-right"></i>
        </el-button>
      </div>
      <div class="Selected-members">
        <span>
          <!-- 已选成员 -->
          {{ $t("vue_label_email_selected_members") }}
        </span>
        <div class="memberList-right">
          <ul>
            <li
              v-show="member.type == type"
              v-for="member in rightData"
              @click="selectMember(member)"
              :key="member.id"
              :style="{
                backgroundColor: member.isSecelt == true ? '#ddd' : '',
              }"
            >
              {{ member.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import * as TargetModel from "./api.js";
export default {
  data() {
    return {
      type: "Role",
      keyworld: "",
      leftData: [],
      rightData: [],
      personOptions: [
        {
          value: "Role",
          label: this.$i18n.t("label_tabpage_rolez"), //角色
        },
        {
          value: "User",
          label: this.$i18n.t("label.document.history.user"), //用户
        },
      ],
    };
  },
  mounted() {
    this.init(this.type, "");
    if (this.modelId != "") {
      this.editMember(this.modelId);
    }
  },
  props: {
    modelId: {
      type: String,
      default: "",
    },
  },
  methods: {
    //编辑时回显已选成员
    async editMember(modelid) {
      let params = {
        type: "",
        modelid: modelid,
        modelKeyword: "",
      };
      let resultData = await TargetModel.FindAssignUser(params);
      if (resultData.result) {
        resultData.data.added.forEach((item) => {
          item.type = "User";
        });
        resultData.data.addedRoleList.forEach((item) => {
          item.type = "Role";
        });
        this.rightData = resultData.data.added.concat(
          resultData.data.addedRoleList
        );
        this.rightData.forEach((value) => {
          this.$set(value, "isSecelt", false);
          for (let i = this.leftData.length - 1; i >= 0; i--) {
            if (this.leftData[i].id == value.id) {
              this.leftData.splice(i, 1);
            }
          }
        });
      }
    },
    //获取已选成员
    curGroupLists() {
      let selecteArr = [];
      this.rightData.forEach((val) => {
        selecteArr.push(val.id);
      });

      this.$emit("memberFun", selecteArr.join(","));
    },
    //获取人员列表
    async init(type, modelKeyword) {
      let params = {
        type: type,
        modelKeyword: modelKeyword,
      };
      let resultData = await TargetModel.FindAssignUser(params);
      if (resultData.result) {
        if (type == "User") {
          resultData.data.unadded.forEach((item) => {
            item.isSecelt = false;
            item.type = "User";
          });
          if (this.rightData.length > 0) {
            this.leftData = resultData.data.unadded;
            this.rightData.forEach((value) => {
              for (let i = this.leftData.length - 1; i >= 0; i--) {
                if (this.leftData[i].id == value.id) {
                  this.leftData.splice(i, 1);
                }
              }
            });
          } else {
            this.leftData = resultData.data.unadded;
          }
        }
        if (type == "Role") {
          resultData.data.roleList.forEach((item) => {
            item.isSecelt = false;
            item.type = "Role";
          });
          if (this.rightData.length > 0) {
            this.leftData = resultData.data.roleList;
            this.rightData.forEach((value) => {
              for (let i = this.leftData.length - 1; i >= 0; i--) {
                if (this.leftData[i].id == value.id) {
                  this.leftData.splice(i, 1);
                }
              }
            });
          } else {
            this.leftData = resultData.data.roleList;
          }
        }
      }
      // if (this.modelId != "") {
      //   this.editMember(this.modelId);
      // }
    },
    //切换人员类型
    tabList(value) {
      this.init(value, "");
    },
    //搜索人员
    searchMember() {
      this.init(this.type, this.keyworld);
    },
    //选中人员
    selectMember(value) {
      value.isSecelt = !value.isSecelt;
    },
    //向右添加人员
    addRight() {
      for (let i = this.leftData.length - 1; i >= 0; i--) {
        if (this.leftData[i].isSecelt == true) {
          let rightItem = this.leftData.splice(i, 1);
          rightItem[0].isSecelt = false;
          this.rightData.push(rightItem[0]);
        }
      }
    },
    //向左添加人员
    addLeft() {
      for (let i = this.rightData.length - 1; i >= 0; i--) {
        if (this.rightData[i].isSecelt == true) {
          let leftItem = this.rightData.splice(i, 1);
          leftItem[0].isSecelt = false;
          this.leftData.push(leftItem[0]);
        }
      }
    },
  },
};
</script>



<style lang="scss" scoped>
::v-deep .el-input-group__prepend {
  width: 50%;
}
::v-deep .el-button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-button + .el-button {
  margin-left: 0;
}
.member-box {
  display: flex;
}
.personnel-select {
  width: 176px;
  .view_members {
    font-weight: bold;
  }
}
.memberList-left {
  width: 176px;
  height: 236px;
  overflow-y: scroll;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  li {
    font-size: 12px;
    padding: 0 10px;
  }
  li:hover {
    background: #ddd;
  }
  .select-style {
    background: #ddd;
  }
}
.Selected-members {
  span {
    white-space: nowrap;
    font-weight: bold;
  }
  .memberList-right {
    width: 176px;
    height: 268px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    li {
      font-size: 12px;
      padding: 0 10px;
    }
    li:hover {
      background: #ddd;
    }
    .select-style {
      background: #ddd;
    }
  }
}
.Operation-button {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}
</style>