/* 
  过滤器中选项带有逗号需要转为首尾添加中括号处理
*/

// 设置筛选条件value值，数组转为字符串逗号拼接，且数组项中若是包含逗号则首尾加中括号
export function setValueTypeConversion(value) {
  let newArr = [];
  // 将数组中带有逗号的数据项根据逗号分隔成对应数组
  value.forEach((childVal) => {
    if (childVal.indexOf(",") !== -1) {
      childVal = childVal.split();
    } else {
      childVal = childVal;
    }
    newArr.push(childVal);
  });
  let newData = "";
  // 判断数组中的数据项是否为数组，若是数组则首尾拼接上中括号
  newArr.forEach((nVal) => {
    if (Array.isArray(nVal)) {
      newData += "[" + nVal + "],";
    } else {
      newData += nVal + ",";
    }
  });
  return newData.slice(0, newData.length - 1);
}

// 回显筛选条件value值，将字符串转为数组，若是数组项中包含逗号则首尾会带有中括号，需去掉首尾的中括号
export function echoValueTypeConversion(value) {
  let newArr = [];
  // 若是数据为空，则直接返回空数组即可
  if (!value) {
    return newArr;
  }
  // 若是数据项中均为不带逗号的数据，直接转为数组即可
  if (!value.match(/\[(.+?)\]/g)) {
    newArr = value.split(",");
    return newArr;
  }
  // 匹配字符串中带有中括号的数据，去掉首尾中括号
  value
    .match(/\[(.+?)\]/g)
    .map((item) => newArr.push(item.replace(/\[|]/g, "")));
  // 将字符串中带有中括号的数据去除
  let valueArr = value.replace(/\[.*?\]/g, "").replace(/^,*|,*$/g, "");
  // 当剩下的不带逗号的数据不为空时，才需要将带有逗号的数组和不带逗号的数组进行拼接
  if (valueArr) {
    valueArr = valueArr.split(",");
    newArr = newArr.concat(valueArr);
  }
  return newArr;
}