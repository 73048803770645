//目标模型
import axios from '@/config/httpConfig'

// 目标模型列表
export function ListModel (data) {
  return axios.post('/target/listModel', data)
}

// 目标模型保存
export function SaveTargetModel (data) {
  return axios.post('/target/saveTargetModel', data)
}

// 考核内容查找对象关系
export function FindFields (data) {
  return axios.post('/target/findFields', data)
}

// 查询时间统计依据
export function StatisticalBasis (data) {
  return axios.post('/target/statisticalBasis', data)
}

// 获取启用范围列表
export function FindAssignUser (data) {
  return axios.post('/target/findAssignUser', data)
}

// 通过选择的角色id查人员
export function AssignUserByCondition (data) {
  return axios.post('/target/assignUserByCondition', data)
}

// 查看目标模型详情
export function ModelDetail (data) {
  return axios.post('/target/modelDetail', data)
}

// 编辑目标模型
export function EditTargetModel (data) {
  return axios.post('/target/editTargetModel', data)
}

// 删除目标模型
export function DeleteTargetModel (data) {
  return axios.post('/target/deleteTargetModel', data)
}

// 获取视图详细信息
export function GetViewInfo(data) {
  return axios.post('/view/getViewInfo', data)
}
