<template>
  <div class="new_target" @click.capture="hideBtn">
    <div class="new_box" v-loading="modelLoading">
      <el-form
        :rules="rules"
        ref="targetForm"
        :model="targetForm"
        :label-width="formLabelWidth"
        label-position="right"
        size="small"
      >
        <el-row>
          <!-- //目标模型 -->
          <h2>{{ $t("label_target_model") }}</h2>
          <el-col :span="12">
            <!-- 目标模型名称 -->
            <el-form-item
              :label="$t('label_target_name')"
              prop="name"
              class="label_name"
            >
              <el-input
                v-model.trim="targetForm.name"
                autocomplete="off"
                class="input_name"
              ></el-input>
            </el-form-item>
            <!-- 启用 -->
            <el-form-item
              :label="$t('label_partner_all_enable')"
              prop="isenable"
              class="label_name"
            >
              <el-checkbox v-model="targetForm.isenable" class="mobileFilter">
              </el-checkbox>
            </el-form-item>
          </el-col>
          <!-- 说明 -->
          <el-col :span="12">
            <el-form-item
              :label="$t('label.explain')"
              prop="description"
              class="label_name"
            >
              <textarea
                name="text"
                v-model="targetForm.description"
                autosize
                :rows="3"
                maxlength="200"
                style="outline-color: none"
              >
              </textarea>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="line_s"></div>
          <!-- 目标模型维度 -->
          <h2>{{ $t("label_target_model_dimension") }}</h2>
          <el-col :span="12">
            <!-- 基准对象 -->
            <el-form-item
              :label="$t('labe_benchmark_objects')"
              prop="obj"
              class="label_name radio_s"
            >
              <el-radio-group v-model="targetForm.obj" @change="queueBtn">
                <!-- 人员 产品 客户 -->
                <el-radio
                  label="user"
                  :disabled="id !== undefined ? true : false"
                >
                  {{ $t("label.weixin.group.member") }}
                </el-radio>
                <el-radio
                  label="201100000006339JMl6w"
                  :disabled="id !== undefined ? true : false"
                >
                  {{ $t("lable.opppdt.addpdt.table.product") }}
                </el-radio>
                <el-radio
                  label="account"
                  :disabled="id !== undefined ? true : false"
                >
                  {{ $t("label.account") }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="label_style">
            <el-form-item prop="isobjFilter" style="margin-bottom: 8px">
              <!-- 筛选基准对象 -->
              <el-checkbox
                v-model="targetForm.isobjFilter"
                :disabled="id !== undefined ? true : false"
                >{{ $t("label_filter_benchmark_objects") }}</el-checkbox
              >
            </el-form-item>
            <el-form-item v-if="targetForm.isobjFilter">
              <subclassFilter
                ref="subclassFilter"
                :list="targetForm.objConditions.data"
                :optionst_one="optionst_oneData"
                :filterName="1"
                :id="id"
                @getFilterData="getFilterObjData"
                @addFilterBtn="addFilterBtn"
                @delsFilterBtn="delsFilterBtn"
                @changeSelect="changeSelect"
                @assignment="getAssignment"
              />
            </el-form-item>
            <!-- <el-form-item>
              <span class="height_tit"> -->
            <!-- 高级筛选 -->
            <!-- {{ $t('advancedconditions') }}
              </span>
              <textarea
                name="text"
                v-model="targetForm.objConditions.filter"
                autosize
                :rows="1"
                class="height_filter"
              >
              </textarea>
            </el-form-item> -->
          </el-col>
        </el-row>
        <el-row>
          <div class="line_s"></div>
          <!-- 考核内容 -->
          <h2>{{ $t("label_assessment_content") }}</h2>
          <el-col :span="12">
            <el-form-item
              :label="$t('label_assessment_content')"
              prop="label"
              class="label_name"
            >
              <el-select
                v-model="targetForm.khfieldid1"
                filterable
                style="width: 110px; margin-right: 10px"
                :disabled="id !== undefined ? true : false"
                :loading="oneLoading"
              >
                <el-option
                  v-for="item in oneOptions"
                  :key="item.id"
                  :label="
                    item.label
                      ? item.label
                      : item.flabel
                      ? item.olabel + '(' + item.flabel + ')'
                      : item.olabel
                  "
                  :value="item.id"
                  @click.native="oneChange(item.schemetableId, item)"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="targetForm.khfieldid2"
                style="width: 110px; margin-right: 10px"
                v-show="twoDisabled"
                filterable
                :disabled="id !== undefined ? true : false"
              >
                <el-option
                  v-for="item in twoOptions"
                  :key="item.id"
                  :label="
                    item.label
                      ? item.label
                      : item.flabel
                      ? item.olabel + '(' + item.flabel + ')'
                      : item.olabel
                  "
                  :value="item.id"
                  @click.native="twoChange(item.schemetableId, item)"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="targetForm.khfieldid3"
                style="width: 110px; margin-right: 10px"
                v-show="threeDisabled"
                filterable
                :disabled="id !== undefined ? true : false"
              >
                <el-option
                  v-for="item in threeOptions"
                  :key="item.id"
                  :label="
                    item.label
                      ? item.label
                      : item.flabel
                      ? item.olabel + '(' + item.flabel + ')'
                      : item.olabel
                  "
                  :value="item.id"
                  @click.native="threeChange(item.schemetableId, item)"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="targetForm.khfieldid4"
                style="width: 110px"
                v-show="fourDisabled"
                filterable
                :disabled="id !== undefined ? true : false"
              >
                <el-option
                  v-for="item in fourOptions"
                  :key="item.id"
                  :label="
                    item.label
                      ? item.label
                      : item.flabel
                      ? item.olabel + '(' + item.flabel + ')'
                      : item.olabel
                  "
                  :value="item.id"
                  @click.native="fourChange(item.schemetableId, item)"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 计算方式 -->
            <el-form-item
              :label="$t('label_alculation_method')"
              prop="label"
              class="label_name"
            >
              <el-select v-model="targetForm.calmode" style="width: 310px">
                <el-option
                  v-for="item in calmodeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="label_style">
            <el-form-item prop="iskhFilter" style="margin-bottom: 8px">
              <!-- 筛选考核内容 -->
              <el-checkbox
                v-model="targetForm.iskhFilter"
                :disabled="id !== undefined ? true : false"
                >{{ $t("label_screening_assessment_content") }}</el-checkbox
              >
            </el-form-item>
            <el-form-item v-if="targetForm.iskhFilter">
              <subclassFilter
                ref="subclassFilterBox"
                :list="targetForm.contentsConditions.data"
                :optionst_one="optionst_one"
                :filterName="2"
                :id="id"
                @getFilterData="getFilterData"
                @addFilterBtn="addFilterBtn"
                @delsFilterBtn="delsFilterBtn"
                @changeSelect="changeSelect"
                @assignment="getAssignment"
              />
            </el-form-item>
            <!-- <el-form-item>
              <span class="height_tit"> -->
            <!-- 高级筛选 -->
            <!-- {{ $t('advancedconditions') }}
              </span>
              <textarea
                name="text"
                v-model="targetForm.contentsConditions.filter"
                autosize
                :rows="1"
                class="height_filter"
              >
              </textarea>
            </el-form-item> -->
          </el-col>
        </el-row>
        <el-row>
          <div class="line_s"></div>
          <!-- 定义考核周期 -->
          <h2>{{ $t("label_efine_assessment_cycle") }}</h2>
          <el-col :span="12" style="display: flex">
            <!-- 考核周期 -->
            <el-form-item :label="$t('label_efine_assessment')" prop="rule">
              <el-select
                v-model="targetForm.rule"
                style="width: 150px"
                :disabled="id !== undefined ? true : false"
              >
                <el-option
                  v-for="item in weekOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label prop="period" class="cycle_s">
              <el-select
                v-model="targetForm.period"
                :disabled="id !== undefined ? true : false"
              >
                <el-option
                  v-for="item in dateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- 时间统计依据 -->
            <el-form-item
              :label="$t('label_time_statistics_basis')"
              prop="tjyiju"
              class="label_name"
            >
              <el-select
                v-model="targetForm.tjyiju"
                style="width: 310px"
                :loading="timeLoading"
              >
                <el-option
                  v-for="item in tjyijuOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="line_s"></div>
          <!-- 启用范围 -->
          <h2>{{ $t("label_enable_range") }}</h2>
          <el-col :span="12">
            <el-form-item
              :label="$t('label_enable_range')"
              prop="label"
              class="label_name"
            >
              <div>
                <Selection ref="selection" @memberFun="memberFun" :modelId="this.$route.query.id">
                
               </Selection>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="footer_box">
            <el-button size="small" @click="cancelBtn('targetForm')">
              <!-- 取消 -->
              {{ $t("label.cancel") }}
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="SaveView('targetForm')"
              style="margin-right: 16px"
            >
              <!-- 确定 -->
              {{ $t("label.h5.act.confirm") }}
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import Selection from './PersonnelSelection.vue'
import subclassFilter from "@/components/Filter/subclassFilter.vue";
import * as TargetModel from "./api.js";

export default {
  components: {
    subclassFilter,
    Selection
  },
  data() {
    return {
      modelLoading: true,
      weekOptions: [
        {
          value: "calendar",
          label: this.$i18n.t("label_calendar_year"), //'日历年度'
        },
      ],
      dateOptions: [
        {
          value: "year",
          label: this.$i18n.t("label.login.access.year"), //'年'
        },
        {
          value: "quarter",
          label: this.$i18n.t("label.account.quarter"), //'季度'
        },
        {
          value: "month ",
          label: this.$i18n.t("month"), //'月'
        },
        // {
        //   value: "day",
        //   label: this.$i18n.t("label.login.access.day"), //'天'
        // },
      ],
      tjyijuOptions: [],
      twoDisabled: false,
      threeOptions: [],
      threeDisabled: false,
      fourOptions: [],
      fourDisabled: false,
      optionst_one: [],
      optionst_oneData: [],
      oneSchemetableId: "",
      twoSchemetableId: "",
      threeSchemetableId: "",
      calmodeOptions: [
        {
          value: "sum",
          label: this.$i18n.t("label_sum_up"), //'求和'
        },
        {
          value: "avg",
          label: this.$i18n.t("label_average_value"), //'平均值'
        },
      ],
      oneOptions: [],
      twoOptions: [],
      oneLoading: true,
      timeLoading: true,
      twoLoading: true,
      targetForm: {
        name: "",
        isenable: true,
        description: "",
        obj: "user",
        isobjFilter: false,
        objConditions: {
          data: [
            {
              fieldId: "",
              op: "",
              val: {
                value: "",
              },
              type: 1,
              id: 1,
              inputType: "input",
              optionst_two: [],
            },
            {
              fieldId: "",
              op: "",
              val: {
                value: "",
              },
              type: 2,
              id: 2,
              inputType: "input",
              optionst_two: [],
            },
            {
              fieldId: "",
              op: "",
              val: {
                value: "",
              },
              type: 3,
              id: 3,
              inputType: "input",
              optionst_two: [],
            },
          ],
          filter: "",
        },
        khid1: "",
        khfieldid1: "",
        khid2: "",
        khfieldid2: "",
        khid3: "",
        khfieldid3: "",
        khid4: "",
        khfieldid4: "",
        calmode: "sum",
        iskhFilter: false,
        contentsConditions: {
          data: [
            {
              fieldId: "",
              op: "",
              val: {
                value: "",
              },
              type: 1,
              id: 1,
              inputType: "input",
              optionst_two: [],
            },
            {
              fieldId: "",
              op: "",
              val: {
                value: "",
              },
              type: 2,
              id: 2,
              inputType: "input",
              optionst_two: [],
            },
            {
              fieldId: "",
              op: "",
              val: {
                value: "",
              },
              type: 3,
              id: 3,
              inputType: "input",
              optionst_two: [],
            },
          ],
          filter: "",
        },
        rule: "calendar",
        period: "year",
        tjyiju: "",
        khfield: "",
        scope: "",
      },
      formLabelWidth: "118px",
      rules: {
        name: [
          //"请输入目标模型名称",//长度在20个字符以内
          {
            required: true,
            message: this.$i18n.t("label.please.enter.the.target.model.name"),
            trigger: "blur",
          },
          {
            max: 20,
            message: this.$i18n.t("label_length_20"),
            trigger: "blur",
          },
        ],
        khfieldid1: [{ required: true, message: "111", trigger: "change" }],
      },
      id: this.$route.query.id,
      editArr: {},
      copyObjConditions: "",
      copyContentsConditions: "",
      //初始数据
      initData: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        type: 1,
        inputType: "input",
        optionst_two: [],
        objid: "",
      },
    };
  },
  mounted() {},
  created() {
    // this.getTreeData(this.cascaderArr)
    this.init();
  },
  methods: {
    async init() {
      //获取启用范围列表
      // TargetModel.FindAssignUser().then(res => {
      //   
      // })
      //考核内容查找对象关系
      if (this.id === undefined) {
        this.modelLoading = false;
        this.findFieldsFun(this.targetForm.obj, []);
      }
      //筛选基准对象
      await TargetModel.GetViewInfo({
        objId: this.targetForm.obj,
        viewId: "",
      }).then((res) => {
        //筛选字段下拉框数据
        if (res.data) {
          this.optionst_oneData = res.data.conditionAllFieldList;
        }
      });
      //查询时间统计依据
      this.statisticalBasis(this.targetForm.obj);
      //查看目标模型详情
      if (this.id !== undefined) {
        this.modelLoading = true;
        await this.getModelDetail();
      }
    },
    async getModelDetail() {
      TargetModel.ModelDetail({ modelid: this.id }).then((res) => {
        if (res.data) {
          let infoData = res.data.result[0];
          //获取编辑id
          this.editArr.id = infoData.id;
          this.editArr.modelid = infoData.modelid;
          //一般字段
          this.targetForm.name = infoData.name && infoData.name;
          this.targetForm.isenable =
            infoData.isenable === "true" ? true : false;
          this.targetForm.description = infoData.description;
          this.targetForm.obj = infoData.obj;
          this.targetForm.calmode = infoData.calmode;
          this.targetForm.rule = infoData.rule;
          this.targetForm.period = infoData.period;
          this.targetForm.tjyiju = infoData.tjyiju;
          this.targetForm.scope = infoData.scope;
          //筛选基准对象
          this.targetForm.isobjFilter =
            infoData.isobjfilter == "true" ? true : false;
          this.queueBtn(infoData.obj, res.data.objcons);
          //如果第四个下拉框有值并且是父级调接口（传当前下拉框schemetableId）
          //如果第四个下拉框有值并且是子级调接口（传上一个下拉框父级schemetableId）
          //如果第四个下拉框无值并且第三个下拉框是父级调接口（传当前下拉框schemetableId）
          //如果第四个下拉框有值并且第三个下拉框是子级调接口（传上一个下拉框父级schemetableId）
          //如果第三个下拉框无值并且第二个下拉框是父级调接口（传当前下拉框schemetableId）
          //如果第三个下拉框有值并且第二个下拉框是子级调接口（传上一个下拉框父级schemetableId）
          //如果第二个下拉框无值并且第一个下拉框是父级调接口（传当前下拉框schemetableId）
          //如果第二个下拉框有值并且第一个下拉框是子级调接口（传上一个下拉框父级schemetableId）
          //如果第一个下拉框无值不调接口（传当前下拉框schemetableId）
          //如果第一个下拉框有值（传当前下拉框父级schemetableId）
          //考核内容四个下拉框的值
          this.targetForm.khfield = infoData.khfield;
          if (infoData.khfieldid1) {
            this.targetForm.khfieldid1 = infoData.khfieldid1;
            this.targetForm.khid1 = infoData.khid1;
            if (infoData.khid1 && infoData.khid1 !== null) {
              // 获取下一级
              this.oneChange(infoData.khid1, []);
            }
          }
          if (infoData.khfieldid2) {
            this.targetForm.khfieldid2 = infoData.khfieldid2;
            this.targetForm.khid2 = infoData.khid2;
            this.twoDisabled = true;
            if (infoData.khid2 && infoData.khid2 !== null) {
              this.twoChange(infoData.khid2, []);
            }
          } else {
            this.twoDisabled = false;
            this.threeDisabled = false;
            this.fourDisabled = false;
            this.selectFiels(infoData.khid1, res.data.contentcons);
            this.statisticalBasis(infoData.khid1);
          }
          if (infoData.khfieldid3) {
            this.targetForm.khfieldid3 = infoData.khfieldid3;
            this.targetForm.khid3 = infoData.khid3;
            this.threeDisabled = true;
            if (infoData.khid3 && infoData.khid3 !== null) {
              this.threeChange(infoData.khid3, []);
            }
          } else {
            this.threeDisabled = false;
            this.fourDisabled = false;
            this.selectFiels(infoData.khid2, res.data.contentcons);
            this.statisticalBasis(infoData.khid2);
          }
          if (infoData.khfieldid4) {
            this.targetForm.khfieldid4 = infoData.khfieldid4;
            this.targetForm.khid4 = infoData.khid4;
            this.fourDisabled = true;
            this.fourChange(infoData.khid4, []);
          } else {
            this.fourDisabled = false;
            this.selectFiels(infoData.khid3, res.data.contentcons);
            this.statisticalBasis(infoData.khid3);
          }

          //考核内容查找对象关系
          // this.findFieldsFun(this.targetForm.obj);

          //筛选考核内容
          this.targetForm.iskhFilter =
            infoData.iskhfilter == "true" ? true : false;
          //已选成员
          // let addData = []
          // if (this.selectData.length > 0) {
          //   res.data.addedUser &&
          //     res.data.addedUser.forEach((val) => {
          //       this.selectData &&
          //         this.selectData.forEach((item) => {
          //           if (val.userid === item.id.split(':')[1]) {
          //             addData.push({
          //               id: 'User:' + val.userid,
          //               text: item.text,
          //               type: 'User',
          //             })
          //           }
          //         })
          //     })
          // }
          // this.selectedGroupList = addData
          this.modelLoading = false
        }
      });
    },
    //获取考核内容数据
    oneChange(val, valItem) {
      this.targetForm.khfieldid2 = "";
      this.targetForm.khfieldid3 = "";
      this.targetForm.khfieldid4 = "";
      this.threeDisabled = false;
      this.twoLoading = true;
      //币种和数字没有下一级，获取筛选器数据
      if (
        (valItem && valItem.schemefieldType === "N") ||
        (valItem && valItem.schemefieldType === "c")
      ) {
        this.targetForm.khfield = valItem.schemefield_name;
        this.twoDisabled = false;
        this.selectFiels(val, []);
        this.targetForm.khid1 = "";
        this.statisticalBasis(val);
      } else {
        TargetModel.FindFields({ id: val }).then((res) => {
          this.twoOptions = [...res.data.fields, ...res.data.childObj];
          this.twoLoading = false;
          //获取最后一项的id（为了编辑回显）
          if (this.targetForm.khfield) {
            this.twoOptions.forEach((itemChild) => {
              if (this.targetForm.khfield === itemChild.schemefield_name) {
                this.targetForm.khfieldid2 = itemChild.id;
              }
            });
          }
          if (this.twoOptions.length > 0 && valItem.schemefieldType) {
            this.twoDisabled = true;
          }
        });
        this.targetForm.khid1 = val;
        this.oneSchemetableId = val;
      }
      this.clearContentFun();
    },
    //获取考核内容数据
    twoChange(val, valItem) {
      this.targetForm.khfieldid3 = "";
      this.targetForm.khfieldid4 = "";
      this.fourDisabled = false;
      if (
        (valItem && valItem.schemefieldType === "N") ||
        (valItem && valItem.schemefieldType === "c")
      ) {
        this.targetForm.khfield = valItem.schemefield_name;
        this.threeDisabled = false;
        this.selectFiels(this.oneSchemetableId, []);
        this.targetForm.khid2 = "";
        this.statisticalBasis(this.oneSchemetableId);
      } else {
        TargetModel.FindFields({ id: val }).then((res) => {
          this.threeOptions = [...res.data.fields, ...res.data.childObj];
          //获取最后一项的id（为了编辑回显）
          if (this.targetForm.khfield) {
            this.threeOptions.forEach((itemChild) => {
              if (this.targetForm.khfield === itemChild.schemefield_name) {
                this.targetForm.khfieldid3 = itemChild.id;
              }
            });
          }
          if (this.threeOptions.length > 0 && valItem.schemefieldType) {
            this.threeDisabled = true;
          }
        });
        this.targetForm.khid2 = val;
        this.twoSchemetableId = val;
      }
      this.clearContentFun();
    },
    //获取考核内容数据
    threeChange(val, valItem) {
      this.targetForm.khfieldid4 = "";
      if (
        (valItem && valItem.schemefieldType === "N") ||
        (valItem && valItem.schemefieldType === "c")
      ) {
        this.targetForm.khfield = valItem.schemefield_name;
        this.fourDisabled = false;
        this.selectFiels(this.twoSchemetableId, []);
        this.targetForm.khid3 = "";
        this.statisticalBasis(this.twoSchemetableId);
      } else {
        this.fourDisabled = true;
        TargetModel.FindFields({ id: val }).then((res) => {
          this.fourOptions = [...res.data.fields, ...res.data.childObj];
          //获取最后一项的id（为了编辑回显）
          if (this.targetForm.khfield) {
            this.twoOptions.forEach((itemChild) => {
              if (this.targetForm.khfield === itemChild.schemefield_name) {
                this.targetForm.khfieldid4 = itemChild.id;
              }
            });
          }
        });
        this.targetForm.khid3 = val;
        this.threeSchemetableId = val;
      }
      this.clearContentFun();
    },
    //获取考核内容数据
    fourChange(val, valItem) {
      this.targetForm.khid4 = val;
      this.targetForm.khfield = valItem.schemefield_name;
      //传上一下拉框值得父级
      this.selectFiels(this.threeSchemetableId, []);
      this.statisticalBasis(this.threeSchemetableId);
    },
    //查询时间统计依据
    statisticalBasis(id) {
      if (id !== null && id !== "") {
        // 如果是客户传'myaccount'
        if (id === "account") {
          id = "myaccount";
        }
        this.timeLoading = true;
        TargetModel.StatisticalBasis({ id: id }).then((res) => {
          this.timeLoading = false;
          if (res.data) {
            this.$nextTick(() => {
              this.tjyijuOptions = res.data.fieldList;
              this.targetForm.tjyiju = res.data.fieldList[0].id;
            });
          }
        });
      }
    },
    //筛选字段下拉框数据
    selectFiels(val, contentcons) {
      if (val !== null && val !== "") {
        TargetModel.GetViewInfo({ objId: val, viewId: "" }).then((res) => {
          this.optionst_one = res.data.conditionAllFieldList;
          let twoArr = [];
          if (
            this.$refs.subclassFilterBox &&
            contentcons.length > 0 &&
            this.optionst_one.length > 0
          ) {
            this.$refs.subclassFilterBox.getFilterObjFun(
              twoArr,
              contentcons,
              this.optionst_one
            );
          }
        });
      }
    },
    //考核内容查找对象关系
    async findFieldsFun(valId) {
      this.oneLoading = true;
      await TargetModel.FindFields({ id: valId }).then((res) => {
        this.oneOptions = [...res.data.fields, ...res.data.childObj];
        this.oneLoading = false;
        this.$nextTick(() => {
          if (this.id === undefined) {
            this.targetForm.khfieldid1 = res.data.fields[0].id;
            this.targetForm.khfield = res.data.fields[0].schemefield_name;
            this.selectFiels(this.targetForm.obj, []);
            this.statisticalBasis(this.targetForm.obj);
          } else {
            if (this.targetForm.khfield) {
              this.oneOptions.forEach((itemChild) => {
                if (this.targetForm.khfield === itemChild.schemefield_name) {
                  this.targetForm.khfieldid1 = itemChild.id;
                }
              });
            }
          }
        });
      });
    },
    getAssignment(msg, filterFlag) {
      if (filterFlag === 2) {
        this.targetForm.contentsConditions.data = msg;
      }
      if (filterFlag === 1) {
        this.targetForm.objConditions.data = msg;
      }
    },
    //获取筛选器下拉框数据
    async queueBtn(val, objcons) {
      this.oneOptions = [];
      this.twoOptions = [];
      this.tjyijuOptions = [];
      this.clearFun();
      await TargetModel.GetViewInfo({ objId: val, viewId: "" }).then((res) => {
        //筛选字段下拉框数据
        this.optionst_oneData = res.data.conditionAllFieldList;
      });
      await this.findFieldsFun(val, []);
      // this.statisticalBasis(this.targetForm.obj)
      if (this.id) {
        let oneArr = [];
        if (this.$refs.subclassFilter) {
          this.$refs.subclassFilter.getFilterObjFun(
            oneArr,
            objcons,
            this.optionst_oneData
          );
        }
      }
    },
    //清空
    clearFun() {
      this.twoDisabled = false;
      this.threeDisabled = false;
      this.fourDisabled = false;
      this.targetForm.khfieldid1 = "";
      this.targetForm.tjyiju = "";
      this.targetForm.objConditions.data = [
        {
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          type: 1,
          id: 1,
          inputType: "input",
          optionst_two: [],
        },
        {
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          type: 2,
          id: 2,
          inputType: "input",
          optionst_two: [],
        },
        {
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          type: 3,
          id: 3,
          inputType: "input",
          optionst_two: [],
        },
      ];
      this.clearContentFun();
    },
    //清空考核内容筛选器
    clearContentFun() {
      this.targetForm.contentsConditions.data = [
        {
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          type: 1,
          id: 1,
          inputType: "input",
          optionst_two: [],
        },
        {
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          type: 2,
          id: 2,
          inputType: "input",
          optionst_two: [],
        },
        {
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          type: 3,
          id: 3,
          inputType: "input",
          optionst_two: [],
        },
      ];
    },
    addFilterBtn(id, name) {
      if (name === 1) {
        this.targetForm.objConditions.data.push({
          ...this.initData,
          id: id,
        });
      } else if (name === 2) {
        this.targetForm.contentsConditions.data.push({
          ...this.initData,
          id: id,
        });
      }
    },
    delsFilterBtn(id, name) {
      if (name === 1) {
        this.targetForm.objConditions.data.splice(id, 1);
      } else if (name === 2) {
        this.targetForm.contentsConditions.data.splice(id, 1);
      }
    },
    changeSelect(row, option, name) {
      if (name === 1) {
        this.$set(this.targetForm.objConditions.data[row - 1], "val", option);
      } else if (name === 2) {
        this.$set(
          this.targetForm.contentsConditions.data[row - 1],
          "val",
          option
        );
      }
    },
    //基准对象
    getFilterObjData(member) {
      this.copyObjConditions = member;
    },
    //考核内容
    getFilterData(member) {
      this.copyContentsConditions = member;
    },
    //获取已选成员
    memberFun(member) {
      this.targetForm.scope = member;
    },
    SaveView(formName) {
      // return false
      //获取筛选基准对象数据
      this.$refs.subclassFilter && this.$refs.subclassFilter.getFilterData();
      //获取考核内容数据
      this.$refs.subclassFilterBox &&
        this.$refs.subclassFilterBox.getFilterData();
      //获取已选成员数据
      this.$refs.selection.curGroupLists()
      //处理已选成员数据
      // let scopeData = this.targetForm.scope && this.targetForm.scope.split(',')
      //对最后一项处理
      if (this.targetForm.khid1 == "") {
        this.targetForm.khfieldid1 = "";
      }
      if (this.targetForm.khid2 == "") {
        this.targetForm.khfieldid2 = "";
      }
      if (this.targetForm.khid3 == "") {
        this.targetForm.khfieldid3 = "";
      }
      if (this.targetForm.khid4 == "") {
        this.targetForm.khfieldid4 = "";
      }
      // let nArr = []
      // scopeData &&
      //   scopeData.forEach((item) => {
      //     if (item !== 'User:' + this.userid) {
      //       nArr.push(item.slice(5))
      //     }
      //   })
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            name: this.targetForm.name,
            isenable: this.targetForm.isenable,
            description: this.targetForm.description,
            obj: this.targetForm.obj,
            isobjFilter: this.targetForm.isobjFilter,
            khid1: this.targetForm.khid1,
            khfieldid1: this.targetForm.khfieldid1,
            khid2: this.targetForm.khid2,
            khfieldid2: this.targetForm.khfieldid2,
            khid3: this.targetForm.khid3,
            khfieldid3: this.targetForm.khfieldid3,
            khid4: this.targetForm.khid4,
            khfieldid4: this.targetForm.khfieldid4,
            calmode: this.targetForm.calmode,
            iskhFilter: this.targetForm.iskhFilter,
            rule: this.targetForm.rule,
            period: this.targetForm.period,
            khfield: this.targetForm.khfield,
            tjyiju: this.targetForm.tjyiju,
            scope: this.targetForm.scope,
          }
          if (this.targetForm.isobjFilter) {
            data.objConditions = JSON.stringify({
              data: this.copyObjConditions,
              filter: this.targetForm.objConditions.filter,
            });
          }
          if (this.targetForm.iskhFilter) {
            data.contentsConditions = JSON.stringify({
              data: this.copyContentsConditions,
              filter: this.targetForm.contentsConditions.filter,
            });
          }
          //编辑保存才需要
          if (this.editArr.id) {
            data.id = this.editArr.id;
            data.modelid = this.editArr.modelid;
            TargetModel.EditTargetModel(data).then((res) => {
              if (res.data && res.data.result) {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: this.$i18n.t("savesuccess"), //保存成功
                });
              }
              this.$router.go(-1);
            });
          } else {
            //新增
            TargetModel.SaveTargetModel(data).then((res) => {
              if (res.data && res.data.result) {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: this.$i18n.t("savesuccess"), //保存成功
                });
              }
              this.$router.go(-1);
            });
          }
        }
      });
    },
    cancelBtn(formName) {
      this.$refs[formName].resetFields();
      this.$router.go(-1);
    },
    //取消可选成员组件的icon
    hideBtn() {
      if (this.$refs.shuttles !== undefined) {
        this.$refs.shuttles.hideBtn();
      }
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.new_target {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  .new_box {
    width: 100%;
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    h2 {
      font-size: 14px;
      color: #080707;
      margin-bottom: 20px;
    }
    .input_name {
      width: 310px;
    }
    textarea {
      width: 358px;
      border-radius: 3px;
      outline-color: none;
      border: 1px solid #dcdfe6;
    }
    .line_s {
      width: 100%;
      height: 1px;
      border-top: 1px solid #dddbda;
      margin-bottom: 10px;
    }
    .height_tit {
      font-size: 12px;
      color: #080707;
      float: left;
    }
    textarea {
      width: 331px;
      margin-left: 10px;
      border-radius: 3px;
      float: left;
      border: 1px solid #dcdfe6;
      outline-color: none;
      float: left;
    }
    ::v-deep .el-form-item__label,
    ::v-deep .el-radio__label,
    ::v-deep .el-checkbox__label {
      font-size: 12px;
      color: #080707;
    }
    .label_style {
      ::v-deep :first-child .el-form-item__content {
        margin-left: 80px !important;
      }
      ::v-deep :nth-child(2) .el-form-item__content {
        margin-left: 103px !important;
      }
      ::v-deep :nth-child(3) .el-form-item__content {
        margin-left: 103px !important;
      }
    }
    .footer_box {
      margin-top: 100px;
      text-align: right;
    }
    .cycle_s {
      width: 150px;
      ::v-deep .el-form-item__content {
        margin-left: 10px !important;
      }
    }
    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      background-color: #006dcc !important;
      border-color: #006dcc !important;
    }
    .radio_s {
      ::v-deep .el-radio-group {
        display: flex;
        line-height: 36px;
        height: 36px;
        align-items: center;
      }
    }
  }
}
</style>
